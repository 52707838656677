<template>
  <div class="case-conent courseware">
    <div class="hd">学历案课件</div>
    <div class="EdTip">注：学历案可以按章节购买，也可以整本购买。按章节购买，请先兑换学币，进入目录进行购买。整本购买请将学历案加入到购物车进行结算。</div>
    <!--搜索条件-->
    <div class="sreach-new">
      <div class="sear-lf">
        <div class="item">
          <el-select placeholder="年份选择" @change="yearChang" @clear="yearClear()" clearable v-model="query.yearId">
            <el-option
                v-for="item in YearData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-select placeholder="学科选择" @change="subjectChang" @clear="subjectClear()" clearable v-model="query.subjectId">
            <el-option
                v-for="item in SubjectData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-select placeholder="年级选择" @change="gradeChang" @clear="gradeClear()" clearable v-model="query.gradeId" >
            <el-option
                v-for="item in GradeData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-select placeholder="学期选择" @change="termChang" @clear="termClear()" clearable v-model="query.termId" >
            <el-option
                v-for="item in SemesterData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-select placeholder="学段选择" @change="studySectionChang" @clear="studySectionClear()" clearable v-model="query.studySectionId" >
            <el-option
                v-for="item in StudySectionData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-select placeholder="教材版本"  @change="textbookEditionChang" @clear="textbookEditionClear()" clearable v-model="query.pressId" >
            <el-option
                v-for="item in bookEditionData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-select placeholder="系列选择" @change="seriesChang" @clear="seriesClear()" clearable v-model="query.brandId">
            <el-option
                v-for="item in SeriesData.slice(1,20)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sear-rg">
        <el-button
                slot="append"
                class="Btn"
                type="primary"
                icon="el-icon-search"
                @click="SearchData()"
        >搜索</el-button>
      </div>
    </div>
    <!--搜索结果-->
    <div class="coursew">
      <div class="Slist" v-if="code==200">
        <li class="Sbooks" v-for="(item, index) in StuBooksData"
            :key="index">
        <span v-if="item.totalDiscount!=undefined" @click="toCatalogue(item)">
        <!--语文-->
        <div class="item-book-bg23" v-if="item.subject =='23'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--数学-->
        <div class="item-book-bg24" v-if="item.subject =='24'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--英语-->
        <div class="item-book-bg25" v-if="item.subject =='25'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>

          <!--政治-->
        <div class="item-book-bg269" v-if="item.subject =='269'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--历史-->
        <div class="item-book-bg242" v-if="item.subject =='242'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--生物-->
        <div class="item-book-bg258" v-if="item.subject =='258'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--综合-->
        <div class="item-book-bg52" v-if="item.subject =='52'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--物理-->
        <div class="item-book-bg237" v-if="item.subject =='237'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
          <!--机械-->
        <div class="item-book-bg283" v-if="item.subject =='283'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
        <div class="Eda">
            <el-tooltip
                    effect="dark"
                    :content="item.name"
                    placement="top"
            >
              <el-button type="text" class="Btn"
                         style="padding: 0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;color: #000">
                {{ item.name }}
              </el-button>

            </el-tooltip>
          </div>
        </span>
          <span v-else>
          <div class="item-book-bg23" v-if="item.subject =='23'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
        <div class="item-book-bg24" v-if="item.subject =='24'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
        <div class="item-book-bg25" v-if="item.subject =='25'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>

            <!--政治-->
        <div class="item-book-bg269" v-if="item.subject =='269'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
            <!--历史-->
        <div class="item-book-bg242" v-if="item.subject =='242'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
            <!--生物-->
        <div class="item-book-bg258" v-if="item.subject =='258'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
            <!--综合-->
        <div class="item-book-bg52" v-if="item.subject =='52'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
            <!--物理-->
        <div class="item-book-bg237" v-if="item.subject =='237'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
            <!--机械-->
        <div class="item-book-bg283" v-if="item.subject =='283'">
          <img :src="item.cover" v-if="item.cover !=''" alt="">
          <img v-else src="../../assets/images/iconFm2.png" alt="">
        </div>
        <div class="Eda">
            <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.name"
                    placement="top"
            >
              <el-button type="text"
                         style="padding: 0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;color: #000">
                {{ item.name }}
              </el-button>

            </el-tooltip>
          </div>
        </span>

          <div class="Edu" v-if="item.isBuy==0">
            <div class="Edu-A">
              <div class="Eca-A" v-if="item.totalDiscount !=undefined">
                <el-tooltip class="item" effect="dark" :content="item.totalDiscount+'学币'" placement="top">
                  <span>{{item.totalDiscount}}学币</span>
                </el-tooltip>
              </div>
              <div class="Eca-B" v-if="item.totalPrice !=undefined">
                <el-tooltip class="item" effect="dark" :content="'原价:'+item.totalPrice+'学币'" placement="top">
                  <span>原价:{{item.totalPrice}}学币</span>
                </el-tooltip>
              </div>
            </div>
            <el-tooltip class="item" effect="dark" content="加入购物车" placement="top">
              <div class="frequency" @click="toBuyOne(item)" v-if="item.totalDiscount!=undefined">
                <el-icon color="#FB7F16" :size="20"><shopping-cart /></el-icon>
              </div>
            </el-tooltip>
          </div>
          <div class="Edu" v-if="item.isBuy==1">
            <span style="color: #0055C5 ">已购买</span>
          </div>
        </li>
      </div>
      <div class="Slist" v-else-if="dlog !=null">
        <div class="kong">
          <img src="../../assets/images/kong.png">
          <p style="color: #1A86FF">请等候！数据在努力加载中...</p>
        </div>
      </div>
      <div class="Slist" v-else>
        <div class="kong">
          <img src="../../assets/images/kong.png">
          <p>暂无查询结果！</p>
        </div>
      </div>
      <!--分页-->
      <div class="pag-box" v-if="StuBooksData.length>0">
        <div class="total-box">
          <span class="total_pag">共<span> {{ total }} </span>条</span>
        </div>
        <el-pagination
                :current-page="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
        >
          <span class="pag_sise">每页显示</span>
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import {
  findScreenYearObj,
  findScreenSubjectObj,
  findScreenGradeObj,
  findScreenSemesterObj,
  findScreenStudySectionObj,
  findScreenTextbookEditionObj,
  findScreenSeriesObj,
  getStuBooksObj
} from '@/api/courseware'   //路径
import {
  addCartObj
} from '@/api/cart'   //路径

export default {
  name: '',
  data() {
    return {
      code:null,
      dlog:null,
      YearData: [],
      SubjectData: [],
      SeriesData: [],
      GradeData: [],
      SemesterData: [],
      StudySectionData: [],
      bookEditionData: [],
      pageSize:12,//每页的数据
      pageCurrent:null,//初始页
      total:null,
      query: {
        yearId:null,
        subjectId:null,
        gradeId:null,
        termId:null,
        studySectionId:null,
        pressId:null,
        brandId: null,
      },
      StuBooksData: []
    }
  },

  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag=this.$route.query.clickFlag;
    this.stu = this.$route.query.stu
    this.currentPage= this.getContextData("CoursewareCurrentPage") || 1;
    this.StuBooksObj()
    this.findScreenSubject()
    this.findScreenYear()
    this.findScreenGrade()
    this.findScreenSemester()
    this.findScreenStudySection()
    this.findScreenTextbookEdition()
    this.findScreenSeries()
  },
  methods: {
    //给sessionStorage存值
    setContextData: function(key, value) {
      if(typeof value == "string"){
        sessionStorage.setItem(key, value);
      }else{
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    },
    // 从sessionStorage取值
    getContextData: function(key){
      const str = sessionStorage.getItem(key);
      if( typeof str == "string" ){
        try{
          return JSON.parse(str);
        }catch(e) {
          return str;
        }
      }
      return;
    },
    // 年份
    yearChang($event){
      sessionStorage.setItem('coursewareYear',$event)
    },
    // 清除年份
    yearClear() {
      sessionStorage.removeItem('coursewareYear');
    },
    // 学科
    subjectChang($event){
      sessionStorage.setItem('coursewareSubject',$event)
    },
    // 清除学科
    subjectClear() {
      sessionStorage.removeItem('coursewareSubject');
    },
    // 年级
    gradeChang($event){
      sessionStorage.setItem('coursewareGrade',$event)
    },
    // 清除年级
    gradeClear() {
      sessionStorage.removeItem('coursewareGrade');
    },
    // 学期
    termChang($event){
      sessionStorage.setItem('coursewareTerm',$event)
    },
    // 清除学期
    termClear() {
      sessionStorage.removeItem('coursewareTerm');
    },
    // 学段
    studySectionChang($event){
      sessionStorage.setItem('coursewareStudySection',$event)
    },
    // 清除学段
    studySectionClear() {
      sessionStorage.removeItem('coursewareStudySection');
    },
    // 教材版本
    textbookEditionChang($event){
      sessionStorage.setItem('coursewareTextbookEdition',$event)
    },
    // 清除教材版本
    textbookEditionClear() {
      sessionStorage.removeItem('coursewareTextbookEdition');
    },
    // 系列
    seriesChang($event){
      sessionStorage.setItem('coursewareSeries',$event)
    },
    // 清除系列
    seriesClear() {
      sessionStorage.removeItem('coursewareSeries');
    },
    //跳转到目录
    toCatalogue(item) {
      this.$router.push({
        path: "/mainb/catalogue",
        query: {
          EducationCase: 1,
          clickFlag:0,
          stu:this.stu,
          bookId: item.id,
          totalPrice: item.totalPrice,
          totalDiscount: item.totalDiscount,
        }
      });
    },
    // 查询筛选的年份列表
    findScreenYear() {
      findScreenYearObj().then((res) => { // eslint-disable-line no-unused-vars
        this.YearData = res.data
      })
    },
    //查询筛选的学科列表
    findScreenSubject() {
      findScreenSubjectObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SubjectData = res.data
      })
    },
    //查询筛选的年级列表
    findScreenGrade() {
      findScreenGradeObj().then((res) => { // eslint-disable-line no-unused-vars
        this.GradeData = res.data
      })
    },
    //查询筛选的学期列表
    findScreenSemester() {
      findScreenSemesterObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SemesterData = res.data
      })
    },
    //查询筛选的学段列表
    findScreenStudySection() {
      findScreenStudySectionObj().then((res) => { // eslint-disable-line no-unused-vars
        this.StudySectionData = res.data
      })
    },
    //查询筛选的教材版本列表
    findScreenTextbookEdition() {
      findScreenTextbookEditionObj().then((res) => { // eslint-disable-line no-unused-vars
        this.bookEditionData = res.data
      })
    },
    //查询筛选的系列
    findScreenSeries() {
      findScreenSeriesObj().then((res) => { // eslint-disable-line no-unused-vars
        this.SeriesData = res.data
      })
    },
    //查询列表
    StuBooksObj() {
      this.dlog= 1
      // 年份
      let coursewareYear = sessionStorage.getItem("coursewareYear")
      if (coursewareYear != undefined && coursewareYear.length > 0) {
        this.query.yearId = Number(coursewareYear)
      } else {
        this.query.yearId = ''
      }
      // 学科
      let coursewareSubject = sessionStorage.getItem("coursewareSubject")
      if (coursewareSubject != undefined && coursewareSubject.length > 0) {
        this.query.subjectId = Number(coursewareSubject)
      } else {
        this.query.subjectId = ''
      }
      // 年级
      let coursewareGrade = sessionStorage.getItem("coursewareGrade")
      if (coursewareGrade != undefined && coursewareGrade.length > 0) {
        this.query.gradeId = Number(coursewareGrade)
      } else {
        this.query.gradeId = ''
      }
      // 学期
      let coursewareTerm = sessionStorage.getItem("coursewareTerm")
      if (coursewareTerm != undefined && coursewareTerm.length > 0) {
        this.query.termId = Number(coursewareTerm)
      } else {
        this.query.termId = ''
      }
      // 学段
      let coursewareStudySection = sessionStorage.getItem("coursewareStudySection")
      if (coursewareStudySection != undefined && coursewareStudySection.length > 0) {
        this.query.studySectionId = Number(coursewareStudySection)
      } else {
        this.query.studySectionId = ''
      }
      // 教材版本
      let coursewareTextbookEdition = sessionStorage.getItem("coursewareTextbookEdition")
      if (coursewareTextbookEdition != undefined && coursewareTextbookEdition.length > 0) {
        this.query.pressId = Number(coursewareTextbookEdition)
      } else {
        this.query.pressId = ''
      }
      // 系列
      let coursewareSeries = sessionStorage.getItem("coursewareSeries")
      if (coursewareSeries != undefined && coursewareSeries.length > 0) {
        this.query.brandId = Number(coursewareSeries)
      } else {
        this.query.brandId = ''
      }
      let params = {
        yearId:this.query.yearId,//年份
        subjectId: this.query.subjectId,//学科
        gradeId: this.query.gradeId,//年级
        termId: this.query.termId,//学期
        studySectionId: this.query.studySectionId,//学段
        pressId: this.query.pressId,//教材版本
        brandId: this.query.brandId,//系列
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      getStuBooksObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.code = res.code
        if(res.code==200) {
          this.dlog= null
          this.StuBooksData = res.data.data.records
          this.pageSize = res.data.data.size;
          this.currentPage = res.data.data.current;
          this.total = res.data.data.total;
        } else if(res.code==209) {
          this.dlog= null
          console.log(res.code)
          this.StuBooksData =[]
        }

      })
    },
    SearchData() {
      this.currentPage = 1;
      sessionStorage.removeItem('CoursewareCurrentPage')
      this.StuBooksObj();
    },
    // 添加购物车
    toBuyOne(item) {
      let params = {
        name:item.name,
        bookId:item.id,
        totalPrice:item.totalPrice,
        totalDiscount:item.totalDiscount,
        coverUrl:item.cover,
        subjectId:Number(item.subject),
      }
      addCartObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.$router.push({
          path: "/mainb/Cart",
          query: {
            EducationCase:this.EducationCase,
            clickFlag:this.clickFlag,
            stu:this.stu,
            acurA:1
          }
        });
        console.log(res,'9993')
      })
    },
    //列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.StuBooksObj();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.setContextData("CoursewareCurrentPage", this.currentPage);
      // 刷新页面内容
      this.StuBooksObj();
    },
    tostuLogin() {
      this.$router.push({
        path: "/stuLogin",
        query: {
        }
      });
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;
  overflow-y: hidden;

  .hd {
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  .EdTip {
    width: 98%;
    line-height: 24px;
    box-sizing: border-box;
    padding: 4px 10px;
    background: #F6F4CE;
    border: 1px solid #FFDBA5;
    opacity: 1;
    color: #EF0000;
    border-radius: 4px;
    margin: 8px auto auto auto;
  }

  .sreachDiv {
    width: 99%;
    background: #ffffff;
    margin: 0px auto auto auto;
    display: flex;
    height: 70px;
    padding: 0px 0px;
    box-sizing: border-box;
    /*border-bottom: 1px dashed #ff6820;*/

    ::v-deep .el-input__inner {
      border: 1px solid rgba(230, 230, 230, 1) !important;
      color: #666;
      text-align: left !important;
      height: 40px;
      line-height: 40px;
    }

    .studentSleft {
      width: 99%;
      margin: auto;
      display: flex;

      .tSle {
        margin-right: 10px;
        //::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
        //    width: 150px;
        //}
      }

    }

    .iconBtnRg {
      width: 117px;
      margin-right: 0;
      float: right;
    }

  }
  .coursew {
    height: 80%;
    .Slist {
      width: 100%;
      height: 89%;
      overflow-y: auto;
      margin-top: 0px;
      //height: 1200px;
      box-sizing: border-box;
      padding: 0 18px;
      margin-bottom: 10px;
      display: inline-block;
      .kong {
        width: 400px;
        margin: auto;
        text-align: center;
        img {
          margin: auto;
          height: 280px;
        }
        p {
          height: 30px;
          line-height: 30px;
          color: #c6c6c6;
          span {
            color: #238bff;
          }
        }
      }

      .Sbooks {
        width: 15.3%;
        height: 260px;
        background: #000;
        border: 1px solid #DDDDDD;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        list-style-type: none;
        float: left;
        margin-right:1.60%;
        background: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        /*text-align: center;*/
        cursor: pointer;
        border: 1px solid rgba(230, 230, 230, 1);
        opacity: 0.9;
        float: left;

        /*语文*/
        .item-book-bg23 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg1.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*数学*/
        .item-book-bg24 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg2.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*英语*/
        .item-book-bg25 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg3.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*政治*/
        .item-book-bg269 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg4.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*历史*/
        .item-book-bg242 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg5.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*生物*/
        .item-book-bg258 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg7.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }

        /*物理*/
        .item-book-bg237 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg8.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*机械*/
        .item-book-bg283 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg9.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*综合*/
        .item-book-bg52 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg11.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*化学*/
        .item-book-bg283 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg9.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        /*技术*/
        .item-book-bg283 {
          width: 100%;
          height: 190px;
          border-radius: 10px 10px 0 0;
          background: url("../../assets/images/item-book-bg9.png") no-repeat;
          background-size: cover;
          img {
            /*width: 358px;*/
            height: 124px;
            margin-top: 20px;
            margin-left: 30px;
            float: left;
            border: 0;
          }
        }
        .Eda {
          width: 96%;
          padding: 6px 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #E5E5E5;
          display: flex;
        }
        .Edu {
          width: 96%;
          margin: 0px auto;
          padding: 6px 4px;
          box-sizing: border-box;
          display: flex;

          .Edu-A {
            width: 198px;
            line-height: 29px;
            box-sizing: border-box;
            vertical-align: center;
            display: flex;


            .Eca-A {
              width: 50%;
              overflow: hidden;
              padding-left: 20px;
              box-sizing: border-box;
              text-overflow: ellipsis;
              background: url("../../assets/images/rech-ioc-01.png") no-repeat -27px -6px;
              background-size: 70px;
              white-space: nowrap;
            }

            .Eca-B {
              width: 50%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-decoration: line-through
            }
          }

          .frequency {
            width: 40px;
            background: url("../../assets/images/case_icon03.png") no-repeat -3px 3px;
            background-size: 21px 18px;
            color: #FF6820;
            text-align: right;
            cursor:pointer;
          }


        }

      }

      li:nth-child(5n+6) {
        border-right: 0;
        margin-right: 0;
        float: left;
        margin-bottom: 20px;
      }

      li:nth-child(n+5) {
        border-bottom: 0
      }

      li:hover {
        box-shadow: 0px 0px 12px 0px rgba(163, 163, 163, 0.35);
      }
    }
    .pag-box {
      width: 100%;
      box-sizing: content-box;
      justify-content: center;
      display: flex;

      .total-box {
        float: left;
        line-height: 32px;
        margin-right: 10px;
        justify-content: flex-end;
        display: flex;

        .el-pagination {
          white-space: nowrap;
          padding: 2px 5px 2px 0px;
          color: #303133;
          font-weight: 700;

          .el-pager li {
            background: #ccc;
            -webkit-box-sizing: border-box;
          }

          .el-pager li .active {
            background-color: #FF6820;
            color: #FFF;
          }
        }


      }

    }
  }



}

</style>
